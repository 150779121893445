module.exports = {
	site_name: '崆峒山年卡',	     						 //网站名称
	main_color: '#038866', 									//程序主色
	oauth_url: 'https://admin.zykongtong.com', 			    //授权域名
	api_url: 'https://niankaapi.zykongtong.com/wechat2/', 	//接口根域名
	api_url2: 'https://niankaapi.zykongtong.com/applet/', 	//接口根域名
	url: 'https://nianka.zykongtong.com', 			    	//访问
	appKey: 'wechat2', 										//接口版本号
	version: 'wechat2', 									//接口key
	appSecret: 'qwertyuiopasdfghjklzxcvbnm123', 			//接口secret
};
