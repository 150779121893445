<template>
    <div>
        <van-nav-bar :title="title" left-arrow placeholder fixed @click-left="url2(url)" />
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            title: '',
            url: '',
        },
        methods: {
            url2(url) {
                this.$emit('callback');
                if (url == -1) {
                    this.$router.go(-1);
                    return false;
                }
                if (url.indexOf('http') !== -1) {
                    location.href = url
                } else {
                    this.$router.push(url)
                }
            },
        }
    }
</script>

<style scoped>
    /deep/ .van-nav-bar__title {
        color: var(--main-color);
        font-family: 'FZCS';
    }
    /deep/ .van-nav-bar__left i{
        color: var(--main-color)!important;
    }
</style>