<template>
    <div>
        <!--底部版权-->
        <div class="copy">
            {{text}}
        </div>

    </div>
</template>

<script>
    export default {
        name: 'commonFooter',
        data() {
			return {
				text:'',
			}
		},
        created() {
            this.init();
        },
        methods: {
			init(){
                var text = '崆峒山智慧旅游©版权所有';
                var that = this;
                setTimeout(function(){
                    that.text = text;
                },1000);
            },
		}
    }
</script>
<style scoped>
    .copy {
        text-align: center;
        font-size: 12px;
        color: #aaa;
        padding-top: 20px;
        padding-bottom: 20px;
    }
</style>