<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <transition :name="transitionName">
      <router-view class="router-view" v-if="!$route.meta.keepAlive"></router-view>
    </transition>
    <common-footer></common-footer>
  </div>
</template>
<script>
  import '@/router'
  export default {
    components: {},
    name: 'App',
    data() {
      return {
        active: 0,
        transitionName: '',
      }
    },
    mounted() {

    },
    methods: {

    }
  }
</script>
<style>
  @import 'static/font/font.css';
  :root {
    --main-color: #038866;
    --money-color: #f40;
  }

  body {
    background: #F2F2F2;
  }

  * {
    margin: 0;
    padding: 0;
  }

  .van-dialog__confirm,
  .van-dialog__confirm:active {
    color: #038866 !important;
  }

  .van-nav-bar .van-icon {
    color: #333!important;
  }

  .van-nav-bar__text {
    color: #333!important;
    font-weight: 700!important;
  }
  .van-nav-bar__title{
    font-weight: 700!important;
  }
  .van-button--info{
    background-color: #038866!important;
    border: 1px solid #038866!important;
  }
</style>