<template>
	<div>
		<div class="h" :style="{height:height + 'px'}">&nbsp;</div>
	</div>
</template>

<script>
	export default {
		props:{
			height:{
				type:Number | String,
				default:10,
			},
		},
	}
</script>

<style>
.h{
	background: #f1f1f1;
	width: 100%;
}
</style>
