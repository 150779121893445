<template>
    <div>
        <van-field label-width="70" label-class="label" type="number" :disabled="token?true:false" v-model="tel" label="手机号码" placeholder="请输入手机号码"
            @input="callback($event,'tel')">
        </van-field>

        <van-field label-width="70" label-class="label" type="number" center label="验证码" placeholder="请输入验证码" v-model="code" v-if="show"
            @input="callback($event,'code')">
            <van-button slot="button" :disabled="disabled" round :color="config.main_color" @click="send_msg" size="small"
                :loading="loading" loading-text="发送中..." type="primary">{{codeButtonText}}</van-button>
        </van-field>
    </div>
</template>

<script>
    import {
        Toast,
        Notify,
    } from 'vant';
    import {
        common_code
    } from '@/api/common.js';
    export default {
        name: 'commonMsg',
        data() {
            return {
                tel: '',
                code: '',
                codeButtonText: '发送验证码',
                disabled: false, //发送短信按钮状态
                is_send: false, //是否已经提交
                loading:false,
            }
        },
        props: {
            token: '',
            show: true,
            paramTel: '',
            paramCode: '',
        },
        watch: {
            paramTel: {
                handler(newName, oldName) {
                    if (newName) {
                        this.tel = newName;
                    }
                },
                immediate: true,
                deep: true,
            },
            paramCode: {
                handler(newName, oldName) {
                    if (newName) {
                        this.code = newName;
                    }
                },
                immediate: true,
                deep: true,
            },
        },
        methods: {
            callback(e, field) {
                var data = {
                    field: field,
                    value: e,
                    type: 'input',
                };
                this.$emit('callback', data);
            },
            send_msg() {
                var that = this;
                var tel = that.tel;
                var disabled = that.disabled;
                //如果按钮不可用
                if (disabled) {
                    return false;
                }

                if (!tel) {
                    Toast('手机号码不得为空');
                    return false;
                }

                if (tel.length !== 11) {
                    Toast('手机号码长度不合法');
                    return false;
                }

                var count = 60; //间隔函数，1秒执行
                var InterValObj1; //timer变量，控制时间
                var curCount1; //当前剩余秒数
                curCount1 = count;

                //开始发送验证码
                this.loading = true;
                common_code({
                    user_tel: tel
                }).then(res => {
                    this.loading = false;
                    if (res.code == 1) {
                        Toast(res.msg);
                        that.codeButtonText = "剩余" + curCount1 + "秒";
                        that.disabled = true;
                        InterValObj1 = setInterval(function () {
                            if (curCount1 == 0) {
                                clearInterval(InterValObj1); //停止计时器
                                that.codeButtonText = "重新发送";
                                that.disabled = false;
                            } else {
                                curCount1--;
                                that.codeButtonText = "剩余" + curCount1 + "秒";
                            }
                        }, 1000); //启动计时器，1秒执行一次
                    }
                });
            },

        }
    }
</script>

<style>
    /deep/ .label,/deep/ .van-field__control{
        color: var(--main-color);
    }
</style>