import request from '@/utils/request'
export function user_info(data) {
    return request({url: 'user_info',data});
}
export function user_set(data) {
    return request({url: 'user_set',data});
}
export function user_query(data) {
    return request({url: 'user_query',data});
}
export function user_query_cate(data) {
    return request({url: 'user_query_cate',data});
}