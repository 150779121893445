import request from '@/utils/request'
export function common_set(data) {
    return request({url: 'common_set',data});
}
export function common_share(data) {
    return request({url: 'common_share',data});
}
export function common_wxsdk(data) {
    return request({url: 'common_wxsdk',data});
}
export function common_page(data) {
    return request({url: 'common_page',data});
}
export function common_slide(data) {
    return request({url: 'common_slide',data});
}
export function common_nav(data) {
    return request({url: 'common_nav',data});
}
export function common_code(data) {
    return request({url: 'common_code',data});
}
export function common_image(data) {
    return request({url: 'common_image',data});
}
export function common_contact(data) {
    return request({url: 'common_contact',data});
}
export function common_address(data) {
    return request({url: 'common_address',data});
}