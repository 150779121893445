
import Vue from 'vue'
import Vuex from 'vuex'
import {common_set } from '@/api/common.js';
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		set: null,
		user: null,
		openid: null,
		unionid: null,
		last:null,//上一步链接
		sale_id:null,//代理缓存
        card:null,//临时办卡信息
        location:null,//位置信息
	},
	getters: {
		user: state => {
			if (!state.user) {
				try {
					let user = localStorage.getItem('user');
					state.user = JSON.parse(user);
				} catch (e) {
					console.error(e);
				}
			}
			return state.user;
		},
        card: state => {
			if (!state.card) {
				try {
					let card = localStorage.getItem('card');
					state.card = JSON.parse(card);
				} catch (e) {
					console.error(e);
				}
			}
			return state.card;
		},
		openid: state => {
			if (!state.openid) {
				try {
					state.openid = localStorage.getItem('openid');
				} catch (e) {
					console.error(e)
				}
			}
			return state.openid;
		},
		sale_id: state => {
			if (!state.sale_id) {
				try {
					state.sale_id = localStorage.getItem('sale_id');
				} catch (e) {
					console.error(e)
				}
			}
			return state.sale_id;
		},
		unionid: state => {
			if (!state.unionid) {
				try {
					state.unionid = localStorage.getItem('unionid');
				} catch (e) {
					console.error(e);
				}
			}
			return state.unionid;
		},
		last: state => {
			if (!state.last) {
				try {
					state.last = localStorage.getItem('last');
				} catch (e) {
					console.error(e)
				}
			}
			return state.last;
		},
        location: state => {
			if (!state.location) {
				try {
					let location = localStorage.getItem('location');
					state.location = JSON.parse(location);
				} catch (e) {
					console.error(e);
				}
			}
			return state.location;
		},
	},
	mutations: {
		setUser(state, user) {
			state.user = user
			localStorage.setItem('user', JSON.stringify(user));
		},
        setCard(state, card) {
			state.card = card
			localStorage.setItem('card', JSON.stringify(card));
		},
		setOpenid(state, openid) {
			state.openid = openid
			localStorage.setItem('openid', openid);
		},
		setUnionid(state, unionid) {
			state.unionid = unionid
			localStorage.setItem('unionid', unionid);
		},
		setSaleId(state, sale_id) {
            let newData = sale_id.replace(/.html/gi, '')
			state.sale_id = newData
			localStorage.setItem('sale_id', newData);
		},
		setLast(state, last) {
			state.last = last
			localStorage.setItem('last', last);
		},
        setLocation(state, location) {
			state.location = location
			localStorage.setItem('location', JSON.stringify(location));
		},
	}
});

