import { user_info } from '@/api/user.js';
import store from '@/store';
import config from '@/static/js/config.js';

const oauth_url = config.oauth_url;

//URL参数转换为JSON对象
function urlParamToJson(url) {
    let json = {};
    if(url.indexOf('?')!==-1){
        url.split('?')[1].trim()
            .split('&')
            .forEach(item => json[item.split('=')[0]] = item.split('=')[1]);

    }
    return json;
}

//授权
export function oauth(from) { 
    const local_unionid = store.getters.unionid;
    const unionid = urlParamToJson(window.location.href)['get_unionid'];
    
    //不需要授权的页面
    var path = window.location.pathname;
    if(path=='/admin' || path.slice(0,3) == '/qr' || path.slice(0,6) == '/photo' || path.slice(0,5) == '/page'){
        return false;
    }
    //用户没有unionid
    if (!local_unionid) {
        if (unionid) {
            store.commit('setUnionid',unionid);
            user_info({unionid:unionid}).then(res=>{
				if(res.code==1){	
					store.commit('setOpenid',res.data.wechat_openid);
					store.commit('setUser',res.data);
				}
			});
        }
        else {
            //授权重定向
           window.location.href = oauth_url + '/portal/unify_oauth/index?url=' + encodeURIComponent(window.location.href);
        }
    }
}

