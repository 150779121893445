//全局引入组件
import Vue from 'vue';


//导入组件
import navBar from '@/components/navBar.vue';
import uploadImage from '@/components/uploadImage.vue';
import gap from '@/components/gap.vue';
import commonMsg from '@/components/commonMsg.vue';
import commonFooter from '@/components/commonFooter.vue';
import { Button } from 'vant';
import { Empty } from 'vant';
import { Swipe} from 'vant';
import { SwipeItem } from 'vant';
import { Grid } from 'vant';
import { GridItem } from 'vant';
import { Image } from 'vant';
import { Loading  } from 'vant';
import { NavBar  } from 'vant';
import { Divider  } from 'vant';
import { Tabbar  } from 'vant';
import { TabbarItem  } from 'vant';
import { Cell  } from 'vant';
import { CellGroup  } from 'vant';
import { Popup  } from 'vant';
import { Icon  } from 'vant';
import { Field  } from 'vant';
import { Uploader  } from 'vant';
import { Collapse  } from 'vant';
import { CollapseItem  } from 'vant';
import { Step  } from 'vant';
import { Steps  } from 'vant';
import { Tag  } from 'vant';
import { Row  } from 'vant';
import { Col  } from 'vant';
import { Tab  } from 'vant';
import { Tabs  } from 'vant';
import { Form  } from 'vant';
import { Stepper  } from 'vant';
import { NumberKeyboard  } from 'vant';
import { CheckboxGroup  } from 'vant';
import { Checkbox  } from 'vant';
import { Radio  } from 'vant';
import { ActionSheet  } from 'vant';
import { Search  } from 'vant';
import { RadioGroup  } from 'vant';
import { Area  } from 'vant';
import { Picker  } from 'vant';
import { List  } from 'vant';
import { NoticeBar  } from 'vant';
import { CountDown  } from 'vant';
import { SubmitBar  } from 'vant';
import { ShareSheet  } from 'vant';
import { Dialog  } from 'vant';
import { DatetimePicker  } from 'vant';
import { Skeleton  } from 'vant';
import { Overlay  } from 'vant';


//注册组件
Vue.component('navBar',navBar);
Vue.component('uploadImage',uploadImage);
Vue.component('gap',gap);
Vue.component('commonMsg',commonMsg);
Vue.component('commonFooter',commonFooter);
Vue.use(Button);
Vue.use(Empty);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(Loading);
Vue.use(NavBar);
Vue.use(Divider);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Tag);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Stepper);
Vue.use(NumberKeyboard);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(RadioGroup);
Vue.use(Area);
Vue.use(Picker);
Vue.use(List);
Vue.use(NoticeBar);
Vue.use(CountDown);
Vue.use(SubmitBar);
Vue.use(ShareSheet);
Vue.use(Dialog);
Vue.use(DatetimePicker);
Vue.use(Skeleton);
Vue.use(Overlay);
