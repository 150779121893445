import axios from 'axios'
import { Dialog ,Toast } from 'vant';
import configs from '@/static/js/config.js';
import store from '@/store';


const md5 = require('js-md5');
const service = axios.create({
    baseURL: configs.api_url,
    timeout: 100000,//请求超时
    method: 'post',
});



service.interceptors.request.use(
    (config) => {
        if (config.data.loading) {
            Toast.loading({
                message: config.data.loading_text ? config.data.loading_text : '加载中...',
                forbidClick: true,
            });
        }


        let sign_time = (new Date()).valueOf();
        let sign = md5(sign_time + configs.appKey + "version=" + configs.version + configs.appSecret);
        let old = config.data;

        try {
            old.time = sign_time;
            old.sign = sign;
            old.openid = store.getters.openid;
            old.unionid = store.getters.unionid;
            old.sale_id = store.getters.sale_id;
            old.location = store.getters.location;
        } catch (e) {
            console.log(e)
        }
        config.data = old;
        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        Toast.clear();
        const res = response.data

        // if the custom code is not 1, it is judged as an error.
        if (res.code !== 1) {
            if(res.msg !== 'Network Error'){
                Dialog.alert({
                    title: '提示',
                    message: res.msg || 'Error：' + JSON.stringify(res),
                    confirmButtonColor: configs.main_color,
                });
            }
            return false;
        } else {
            return res
        }
    },
    error => {
        // Dialog.alert({
        //     title: '提示',
        //     message: error.message,
        //     confirmButtonColor: configs.main_color,
        // });
        return Promise.reject(error)
    }

)

export default service
