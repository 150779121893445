<template>
    <div>
        <van-uploader v-model="fileList" :after-read="afterRead" :before-delete="deleteImage" :max-count="max" />
    </div>
</template>

<script>
    import { Dialog, Toast } from 'vant';
    import { common_image } from '@/api/common.js';
    export default {
        name: 'upload',
        data() {
            return {
                fileList: [],//展示
                fileData: [],//数据
            }
        },
        props: {
            max: 0,
            list: [],
        },
        created() {
            for(var item of this.list){
                this.fileList.push({url:item});
            }
        },
        methods: {
            afterRead(file) {
                if(this.fileData==undefined){
                    this.fileData = [];
                }
                file.status = 'uploading';
                file.message = '上传中...';
                var form = new FormData();
                form.set("file", file.file);
                Toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: '上传中……',
                });
                common_image(form).then(res => {
                    Toast.clear();
                    file.status = 'done';
                    file.message = '上传成功';
                    this.fileData.push(res.data);
                    this.$emit('callback', this.fileData);
                });
            },

            deleteImage(e,detail) {
                let index = detail[index];
                let that = this;
                that.fileData.splice(index,1);
                that.$emit('callback', that.fileData);
                return (file, name) => {
                    let fileIndex = name.index;
                    that.fileList[e].splice(fileIndex,1);
                }
            },
        },
    }
</script>

<style scoped>
    /deep/ .van-uploader__upload{
        background: #F2F2F2;
    }
</style>